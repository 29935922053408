const Background = () => {
  return (
    <div className="drops-container">
      <div className="drops">
        <div className="s"></div>
        <div className="m"></div>
        <div className="b"></div>
      </div>
    </div>
  )
}
export default Background;